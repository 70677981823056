
import { defineComponent, ref } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import StatusesList from '@/components/pages/shared/StatusesList.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import { colorPaletteOptionsOutline } from '@/definitions/_general/_data/colorPaletteOptions'
import { taskStatuses } from '@/definitions/tasks/data'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import useTasks from '@/compositions/tasks/useTasks'

export default defineComponent({
  components: {
    PageContent,
    WhiteBlock,
    DetailsHero,
    StatusesList,
  },
  setup() {
    const { openDeleteBoardStage } = useTasks()
    const breadcrumbs: BreadcrumbsLink[] = [
      {
        label: 'Tasks',
        name: 'base.tasks.index',
      },
      {
        label: 'Boards',
        name: 'base.tasks.settings',
      },
      {
        label: 'Marketing',
        name: 'base.tasks.settings.detailed',
      },
      {
        label: 'Stages',
      },
    ]
    const statuses = ref(taskStatuses)

    return {
      breadcrumbs,
      statuses,
      openDeleteBoardStage,
      colorPaletteOptionsOutline,
    }
  },
})
